import {
  EmailAuthProvider,
  getAuth,
  isSignInWithEmailLink,
  signInWithCredential,
  signInWithEmailLink,
} from "firebase/auth";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";
import shallow from "zustand/shallow";
import TextInputDialog from "../components/dialog/text-input";
import Loading from "../components/loading";
import { track } from "../libs/analytics";
import { getIdTokenKeyFromFirebase } from "../libs/auth/id-token";
import { checkIfIsNewUser } from "../libs/auth/utils";
import { posterUsingCustomToken } from "../libs/fetcher";
import firebaseApp from "../libs/firebase/app";
import { reportError, reportWarning } from "../libs/sentry/logger";
import { theme } from "../libs/styled";
import useSnackbarStore from "../state/snackbar-store";
import useUserStore from "../state/user-store";
import { PostMergeResponse } from "../types/api";
import { SnackbarLength } from "../types/snackbar-message";

const Login: React.FC = () => {
  const router = useRouter();
  const {
    query: { redirect },
  } = router;

  const [inputOpen, setInputOpen] = useState(false);

  const [userIsLoading, setDisplayGuide] = useUserStore((state) => [
    state.loading,
    state.setDisplayGuide,
  ]);
  const pushSnackbar = useSnackbarStore((state) => state.pushSnackbar, shallow);

  const auth = getAuth(firebaseApp);

  const signInSuccessCallback = useCallback(() => {
    localStorage.removeItem("emailForSignIn");

    let tries = 0;

    setTimeout(async function ensureToken() {
      tries += 1;

      const token = await getIdTokenKeyFromFirebase();

      if (!token) {
        // No token is found, let's try again?
        reportWarning(`Could't find any token. ${tries} tries have been made.`);
        if (tries <= 5) {
          setTimeout(() => {
            // Try again!
            ensureToken();
          }, 500);
        } else {
          reportWarning(`Giving up finding a token after ${tries} tries.`);
          pushSnackbar({
            message: "Något gick fel. Försök gärna senare!",
            isCloseable: true,
          });
        }
      } else {
        // Token found, run callback
        pushSnackbar({
          message: "Du är nu inloggad!",
          autoHideDuration: SnackbarLength.SHORT,
          isCloseable: true,
        });
        if (typeof redirect === "string") router.replace(`${redirect}`);
        else router.replace("/");
      }
    }, 500);
  }, [redirect]);

  const signInWithEmail = useCallback((email: string) => {
    setInputOpen(false);
    if (auth.currentUser?.isAnonymous) {
      // Merge user
      track("User Merge Opened", {
        provider_id: "email",
      });

      const anonymousUser = auth.currentUser;

      const credential = EmailAuthProvider.credentialWithLink(
        email,
        window.location.href
      );

      signInWithCredential(auth, credential)
        .then((resp) => {
          // Signed in with credential => let's merge anonymous user with the one signed in
          anonymousUser.getIdToken().then((idToken) => {
            posterUsingCustomToken<PostMergeResponse>(
              "/api/users/merge",
              idToken,
              {
                body: JSON.stringify({
                  oldUser: anonymousUser.uid,
                  newUser: resp.user?.uid,
                }),
              }
            )
              .then(() => {
                track("User Merge Completed");
                anonymousUser.delete();
                signInSuccessCallback();
              })
              .catch((err) => {
                track("User Merge Cancelled");
                reportError(err);
                router.replace("/");
                pushSnackbar({
                  message: "Något gick fel. Försök gärna senare!",
                  isCloseable: true,
                });
              });
          });
        })
        .catch((e) => {
          track("User Merge Cancelled");
          reportError(e);
          router.replace("/");
        });
    } else {
      track("Login Opened", {
        provider_id: "email",
      });

      signInWithEmailLink(auth, email.trim(), window.location.href)
        .then((user) => {
          const isNewUser = checkIfIsNewUser(user.user);
          track("Login Completed", {
            is_anonymous: false,
            is_new_user: isNewUser,
            provider_id: user.providerId,
            operation_type: user.operationType,
          });
          if (isNewUser) {
            track("New User Registered", {
              is_anonymous: false,
              provider_id: user.providerId,
              operation_type: user.operationType,
            });
          }
          setDisplayGuide(isNewUser);
          signInSuccessCallback();
        })
        .catch((err) => {
          reportError(err);
          track("Login Cancelled", {
            closed_by_user: false,
          });
          router.replace("/");
        });
    }
  }, []);

  const handleClose = useCallback(() => {
    setInputOpen(false);
    router.replace("/");
  }, []);

  useEffect(() => {
    if (!redirect) return;
    if (userIsLoading) return;
    else {
      if (auth.currentUser && !auth.currentUser.isAnonymous) {
        track("Login Aborted", {
          already_signed_in: true,
        });
        if (typeof redirect === "string") router.replace(`${redirect}`);
        else router.replace("/");
      }
      if (isSignInWithEmailLink(auth, window.location.href)) {
        const email = localStorage.getItem("emailForSignIn");

        if (email) signInWithEmail(email);
        else setInputOpen(true);
      } else {
        router.replace("/");
      }
    }
  }, [redirect, userIsLoading]);

  return (
    <>
      <Loading />
      <TextInputDialog
        color={theme.color.blue.default}
        open={inputOpen}
        onConfirm={signInWithEmail}
        onClose={handleClose}
        title="Ange email"
        text="Ange din email för att fortsätta"
        textFieldTitle="Email"
        confirmText="OK"
        closeText="Avbryt"
      />
    </>
  );
};

export default Login;
