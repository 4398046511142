interface SnackbarMessageAction {
  text: string;
  callback: (event: React.SyntheticEvent | MouseEvent) => void;
}

export enum SnackbarLength {
  SHORT = 4000,
  LONG = 7000,
}

export interface SnackbarMessage {
  message: React.ReactNode;
  key?: number;
  autoHideDuration?: number;
  isCloseable?: boolean;
  action?: SnackbarMessageAction;
  closeCallback?: (event: React.SyntheticEvent | MouseEvent) => void;
  snackbarStyle?: React.CSSProperties;
}
