import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

const pulse = keyframes`
  0% {
    opacity: 1;
    width: 90%;
  }
  50% {
    opacity: .4;
    width: 85%;
  }
  100% {
    opacity: 1;
    width: 90%;
  }
`;

const SVGContainer = styled.svg`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 24rem;
  animation: ${pulse} 1.5s ease-in-out 0.5s infinite;
`;

const Loading: React.FC = React.memo(() => {
  return (
    <SVGContainer version="1.1" fill="none" viewBox="0 0 512 512">
      <path
        d="M316.7 339.5H185.9a10 10 0 00-10 10v7.3a10 10 0 0010 10h130.8a10 10 0 0010-10v-7.4a10 10 0 00-10-10z"
        fill="#EBEBEB"
      />
      <path
        d="M348 456.6H154.5a8.6 8.6 0 00-8.6 8.6v20.1c0 4.8 3.9 8.6 8.6 8.6H348c4.8 0 8.6-3.8 8.6-8.6v-20.1c0-4.8-3.8-8.6-8.6-8.6z"
        fill="#F5F5F5"
      />
      <path
        d="M348 456.6h-31.4v8.7c0 4.8-3.8 8.6-8.6 8.6H146v11.4c0 4.8 3.8 8.6 8.5 8.6H348c4.8 0 8.6-3.8 8.6-8.6v-20.1c0-4.8-3.8-8.6-8.6-8.6z"
        fill="#F0F0F0"
      />
      <path
        d="M313.7 366.8H188.9l-5 26.5a61 61 0 01-16.4 31.7l-4.4 4.5c-5.5 5.6-8.6 13-8.6 20.9v6.2H348V448c0-8.5-3.7-16.7-10.1-22.4A61.1 61.1 0 01318 391l-4.4-24.1z"
        fill="#F5F5F5"
      />
      <path
        d="M337.9 425.6A61.1 61.1 0 01318 391l-4.4-24.1h-36.3l.7 4a61.1 61.1 0 0019.8 34.8A29.8 29.8 0 01308 428c0 4.7-3.8 8.6-8.5 8.6H157.9c-2.2 4.2-3.4 9-3.4 13.8v6.2H348V448c0-8.5-3.7-16.7-10.1-22.4z"
        fill="#F0F0F0"
      />
      <path
        d="M169.7 287h-25.4c-4.3 0-7.4-3-7.4-7.3v-89.1c0-76 53.4-140 124.6-155.9a38.7 38.7 0 00-2.8 14.5v25a119.4 119.4 0 00-84.8 106c-.6 7-2.5 4.8-2.5 74.6 0 10.8-.6 21.6-1.7 32.3zM343.5 41.5A109.8 109.8 0 00294 30.9c-4.3 0-8.5.3-12.7.7l10 35 3.3-.2 2 2.1c12.8-.4 23.9-.5 35.9 6l14.3-21.6a8 8 0 00-3.3-11.5z"
        fill="#EBEBEB"
      />
      <path
        d="M397.5 216.4l-9.7-11.9a72.3 72.3 0 01-16.2-54.4 75.6 75.6 0 00-80.3-84.4l-13.2-44.8c-.6-2.3-3.4-3.1-5.2-1.7a38.7 38.7 0 00-14.2 30v26.5a75.6 75.6 0 00.1 130.7l68.7 39.7c1.5.8 2.4 2.4 2.4 4.2-.1 5 2.1 9.7 6.4 12.2l26 15a19.7 19.7 0 0026.9-7.2l11.1-19.3c6.4-11 5.2-24.8-2.8-34.6z"
        fill="#F5F5F5"
      />
      <path
        d="M397.5 216.4l-9.7-11.9a72.3 72.3 0 01-16.2-54.4A75.6 75.6 0 00309 66.5a75.7 75.7 0 0122.6 63.6 72.3 72.3 0 0016.2 54.4l9.7 11.9c8 9.8 9.2 23.6 2.8 34.6l-11.1 19.3c-3.5 6-9.5 9.4-15.9 9.8.9 1 1.9 1.8 3 2.4l26 15a19.7 19.7 0 0026.9-7.2l11.1-19.3c6.4-11 5.2-24.8-2.8-34.6z"
        fill="#F0F0F0"
      />
      <path
        d="M314.4 339.5H186c-13 0-22.8-11.7-20.6-24.4l1.7-10a300 300 0 004.2-50.3v-64.2c0-56 36.7-103.3 87.3-119.3v4.3a75.6 75.6 0 00-4.3 128c3.3 2.2-2-1 42.2 24.6l9 5.2 16.4 34.9a143 143 0 0113.2 48.4c1 12.3-8.6 22.8-20.8 22.8z"
        fill="#F5F5F5"
      />
      <path
        d="M335.2 318.7v-2c-1.5-16.8-6-33.2-13.2-48.4l-16.5-35-8.9-5c-44.3-25.7-39-22.5-42.2-24.7a73.8 73.8 0 014.3-126v-6.3A125.5 125.5 0 00183 138a74.4 74.4 0 0031.4 45.6c3.3 2.2-2-1 42.2 24.6a33 33 0 0113.4 14.5l12 25.6a143 143 0 0113.2 48.4c1 12.2-8.6 22.8-20.8 22.8H165.2c.4 10.9 9.4 20 20.9 20h128.3c11.5 0 20.8-9.4 20.8-20.8z"
        fill="#F0F0F0"
      />
    </SVGContainer>
  );
});

export default Loading;
