import styled from "@emotion/styled";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export const StyledDialogTitle = styled(DialogTitle)`
  & .MuiTypography-root {
    font-family: ${(props) => props.theme.fontFamily.system};
  }
`;

export const StyledDialogContentText = styled(DialogContentText)`
  font-family: ${(props) => props.theme.fontFamily.system};
`;

interface ButtonProps {
  textColor: string;
}

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "textColor",
})<ButtonProps>`
  color: ${(props) => props.textColor};
  font-family: ${(props) => props.theme.fontFamily.system};
`;

export interface SimpleDialogProps {
  title: string;
  open: boolean;
  onClose: () => void;
}

export interface InputDialogProps extends SimpleDialogProps {
  text: string;
  closeText: string;
  confirmText: string;
  color: string;
}
