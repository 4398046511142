import styled from "@emotion/styled";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import React, { useCallback, useEffect, useState } from "react";
import {
  InputDialogProps,
  StyledButton,
  StyledDialogContentText,
  StyledDialogTitle,
} from "./common";

interface Props extends InputDialogProps {
  onConfirm: (text: string) => void;
  textFieldPlaceholder?: string;
  textFieldTitle: string;
}

interface InputProps {
  inputColor: string;
}

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "inputColor",
})<InputProps & TextFieldProps>`
  font-family: ${(props) => props.theme.fontFamily.system};
  & .MuiFormLabel-root {
    font-family: ${(props) => props.theme.fontFamily.system};
  }
  & .MuiInputBase-root {
    font-family: ${(props) => props.theme.fontFamily.system};
  }
  & label.Mui-focused {
    color: ${(props) => props.inputColor};
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${(props) => props.inputColor};
  }
`;

const TextInputDialog: React.FC<Props> = React.memo(
  ({
    open,
    title,
    text,
    confirmText,
    closeText,
    onClose,
    onConfirm,
    textFieldTitle,
    textFieldPlaceholder,
    color,
  }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [textValue, setTextValue] = useState(textFieldPlaceholder);

    useEffect(() => {
      if (open) {
        setIsOpen(true);
      }
    }, [open]);

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextValue(event.target.value);
      },
      [setTextValue]
    );

    const onExited = useCallback(() => {
      setIsOpen(false);
    }, [setIsOpen]);

    const handleConfirm = useCallback(() => {
      onConfirm(textValue ? textValue.trim() : "");
    }, [textValue, onConfirm]);

    const onKeyPress = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
          handleConfirm();
        }
      },
      [handleConfirm]
    );

    return isOpen ? (
      <Dialog
        open={open}
        onClose={onClose}
        onExited={onExited}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle id="alert-dialog-title">{title}</StyledDialogTitle>
        <DialogContent>
          <StyledDialogContentText id="alert-dialog-description">
            {text}
          </StyledDialogContentText>
          <StyledTextField
            autoFocus
            inputColor={color}
            margin="dense"
            label={textFieldTitle}
            value={textValue}
            onChange={handleChange}
            onKeyPress={onKeyPress}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <StyledButton textColor={color} onClick={onClose}>
            {closeText}
          </StyledButton>
          <StyledButton textColor={color} onClick={handleConfirm}>
            {confirmText}
          </StyledButton>
        </DialogActions>
      </Dialog>
    ) : null;
  }
);

export default TextInputDialog;
